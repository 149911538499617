import { Props as ButtonProps } from 'frr-web/lib/components/Button'
import { FormField } from 'frr-web/lib/form/components/types'
import { PlatformDataT } from 'frr-web/lib/hooks/usePlatform'
import { StyleConfigDTO } from 'frr-web/lib/theme/configureBaseStyle'
import { DeprecatedLanguage, DeprecatedLanguage as Language } from 'frr-web/lib/theme/language'
import { ComponentThemeConfig } from 'frr-web/lib/theme/theme.components'
import { FormThemeConfig } from 'frr-web/lib/theme/theme.form'
import { DeepPartial } from 'frr-web/lib/util'
import { ReactNode } from 'react'
import { NavigateFunction } from 'react-router'
import {
  useClearFinObj,
  usePatchFinObj,
  useSetModuleSystem,
} from '../data/mutations/financingObject.mutations'
import { StateLevels } from '../shared/helpers.stateLevel'
import { AppTheme, AppThemeConfig } from '../shared/theme/app.theme'
import { ModuleTheme, ModuleThemeConfig } from '../shared/theme/module.theme'
import { useTrackEvent } from '../view/hooks/useTrackEvent'
import { AppConfig } from './app.types'
import { CalculatorModulePropsT } from './calculator.types'
import { FinancingObject, ProductSettingsT, StateLevel } from './financing.types'
import { FinancingObjectFormProps, PreFinancingObjectFormProps } from './form.types'
import { Translate } from './helper.types'
import { PartnerContactPersonData, PartnerInfoFormProps } from './partner.types'
import { Dispatch } from '@reduxjs/toolkit'
import { AppState } from '../data/redux/appState.redux'

export enum UserType {
  Partner = 'partner',
  Customer = 'customer',
  CustomerVerification = 'customer-verification',
}

export enum PlatformState {
  UnsupportedDevice = 'Unsupported Device',
  UnsupportedOS = 'Unsupported OS',
  UnsupportedBrowser = 'Unsupported Browser',
  UnsupportedBrowserVersion = 'Unsupported Browser Version',
}

export enum Module {
  Calculator = 'Calculator',
  CreditIdentityDataRetrievalSelection = 'CreditIdentityDataRetrievalSelection',
  CreditConfirmation = 'CreditConfirmation',
  Custom = 'Custom',
  CustomerIDVerification = 'CustomerIDVerification',
  CustomerIntroduction = 'CustomerIntroduction',
  CustomerPlanSummary = 'CustomerPlanSummary',
  Form = 'Form',
  Generic = 'Generic',
  Information = 'Information',
  Processing = 'Processing',
  PartnerOnboardingForm = 'PartnerOnboardingForm',
  PartnerOnboardingConfirmation = 'PartnerOnboardingConfirmation',
  PaymentTerms = 'PaymentTerms',
  Waiting = 'Waiting',
}

export enum ModuleState {
  Error = 'Error',
  Loading = 'Loading',
  Ready = 'Ready',
  Rejection = 'Rejection', // Helps for better tracking in AppInsights and a clearer distinguishing between error and rejection in the module systems
  Warning = 'Warning', // Helps for better tracking in AppInsights and a clearer distinguishing between error and rejection in the module systems
}

export type FinancingTermsT = {
  amount: number
  duration: number
  hasPpi: boolean
}

export type ModuleFooterProps = {
  next: () => void
  previous?: () => void
  style?: AppTheme['moduleFooter']
  nextLabel?: string
  previousLabel?: string
}

export type ModuleBottomTextProps = {
  text: string
  style?: Partial<AppTheme['moduleBottomText']>
}

type ModuleContentProps = Partial<{
  bottomText: ModuleBottomTextProps
  loadingText?: string
}>

type CommonModuleProps = {
  moduleHeader?: {
    description?: string
    isNavigationHidden?: boolean
    style?: DeepPartial<AppTheme['moduleHeader']>
    title: string
  }
  moduleFooter?: ModuleFooterProps
  moduleContent?: ModuleContentProps
  hasMerchantBanner?: boolean
  hideFooter?: boolean
  isChangeLanguageDisabled?: boolean // If set the language can no more be changed
  isModuleLoadingOff?: boolean // Do not show any animation for next module loading and do not scroll to top
  isPatchOnChangeLanguageActive?: boolean // If set a language change results in patching the financing object immediately
}

export type CalculatorModuleProps = CalculatorModulePropsT & {
  type: Module.Calculator
}
export type CreditIdentityDataOption = {
  buttonProps: Omit<ButtonProps, 'onClick'> & {
    onClick: (params: {
      appConfig: AppConfig
      dispatch: Dispatch
      finObj: FinancingObject
      navigate: NavigateFunction
      patchFinObj: ReturnType<typeof usePatchFinObj>['mutateAsync']
    }) => void
  }
  description?: string
  icon?: string
  infoList?: Array<string>
  infoDescription?: string
  isDisabled?: (params: { finObj: FinancingObject; hasPartnerBankAccount: boolean | null }) => boolean
  isPrimary?: boolean
  label: string
  value: 'yes' | 'no'
}

export type CreditIdentityDataRetrievalSelectionProps = {
  type: Module.CreditIdentityDataRetrievalSelection
  backButtonProps?: Omit<ButtonProps, 'onClick'> & {
    onClick: (params: { setModuleSystem: ReturnType<typeof useSetModuleSystem> }) => void
  }
  introduction: string
  options: Array<CreditIdentityDataOption>
}

export type CreditSummaryProps = {
  type: Module.CreditConfirmation
  getDocumentListToSendToBob?: (finObj: FinancingObject) => Array<string>
  contactInformation?: Array<{ text: string; icon: string; link: string }>
}

export type CreditConfirmationProps = CreditSummaryProps & InjectedModuleProps

export type SummaryFormState = {
  // Loan
  duration: number
  interestRateAmount: number
  loanAmount: number
  monthlyRate: number | null
  provider: string
  totalAmount: number // sum of duration and interestRateAmount + fees - FE only
  // Addresses
  billingAddress: string
  previousAddress: string
  shippingAddress: string
  // Legal
  acceptContract: boolean
  acceptDataPrivacy: boolean
  acceptDataPrivacyAndAGB: boolean
  acceptNewsletter: boolean
  email: string
  emailValid: boolean
  linkToAGB: string | null
}

export type RequestParams = {
  radioCreditProtection: string | null
  requestedLoanHasPPI: string | null
  requestedLoanAmount: string | null
  requestedLoanDuration: string | null
}

export type PreFinancingObject = {
  variantId: string
  amount: number
  duration: number
  orderReference: string | null
  agentCode: string | null
  hasPpi: boolean
  isAgePolicy: boolean
  isResidencyPolicy: boolean
  isOccupancyPolicy: boolean
  isDebtsPolicy: boolean
  isBobPolicyAccepted: boolean
  isPartnerPolicyAccepted: boolean
  isNationalityPolicy: boolean
  interestRate: number
  financingPartner: string
}

type CommonFormModuleProps = {
  type: Module.Form
}

export type FormModuleProps = (FinancingObjectFormProps | PreFinancingObjectFormProps) &
  CommonFormModuleProps

export type ContactSectionProps = {
  description?: string
  title?: string
  style?: Partial<ModuleTheme['contactSection']>
}

export type CustomerIntroductionProps = {
  type: Module.CustomerIntroduction
  steps: (params: {
    financing: FinancingObject
    product: ProductSettingsT
    stateLevels: StateLevels
  }) => Steps
}

export type CustomerIDVerificationModuleProps = {
  type: Module.CustomerIDVerification
}
export type InformationModuleProps = {
  type: Module.Information
  alternativeContent?: {
    showAlternativeContent: (params: {
      financing: FinancingObject
      platformData: PlatformDataT
    }) => boolean
    alternativeContentData?: (params: { financing: FinancingObject; platformData: PlatformDataT }) => {
      description: string
      icon: string
    }
  }
  buttonProps?: Omit<ButtonProps, 'onClick'> & {
    onClick: (params: {
      financingPatch: ReturnType<typeof usePatchFinObj>['mutateAsync']
      isDisplayInline?: boolean
      language: Language
      product: ProductSettingsT
    }) => void
  }
  dataTestId?: string
  description: string | ((financing?: FinancingObject) => string)
  icon: string
  isPollActive?: boolean
  redirectUrl?: (params: {
    financing: FinancingObject
    language: string
    platformData: PlatformDataT
    product: ProductSettingsT
  }) => string | null
  resendEmailProps?: {
    buttonLabel: string
    label: string
    notifications: {
      success: string
      error: string
    }
  }
}

type ProcessingContent = {
  description?: string
  icon?: string
  buttonProps?: Omit<ButtonProps, 'onClick'> & {
    onClick: (params: { setModuleSystem: ReturnType<typeof useSetModuleSystem> }) => void
  }
}
export type ProcessingModuleProps = {
  type: Module.Processing
  dataTestId?: string
  processingContent: ProcessingContent
  successContent?: ProcessingContent
  isSuccess?: (params: {
    setIsSuccess: (v: boolean) => void
    setModuleSystem: ReturnType<typeof useSetModuleSystem>
  }) => void
  style?: Partial<ModuleTheme['processing']>
}

export type CustomerPlanSummaryModuleProps = {
  type: Module.CustomerPlanSummary
  formFields: Array<FormField<SummaryFormState>>
  isPollActive?: boolean
  hasPlatformData?: boolean
}

export type GenericButtonProps<AdditionalData extends {}> = Omit<ButtonProps, 'onClick'> & {
  isDisabled?: (finObj?: FinancingObject | undefined) => boolean
  onClick: (params: {
    additionalData?: AdditionalData
    clearFinObj: ReturnType<typeof useClearFinObj>
    configuration: PartnerFrontendConfiguration
    finObj?: FinancingObject
    isDisplayInline?: boolean
    language: Language
    patchFinObj: ReturnType<typeof usePatchFinObj>['mutateAsync']
    product: ProductSettingsT
    setModuleSystem: ReturnType<typeof useSetModuleSystem>
    trackEvent: ReturnType<typeof useTrackEvent>
    translate: (v: string) => string
  }) => void
}

export type Poll = {
  func: (params: { getFinObj: () => void }) => void
  interval: number
}
type PrimaryButtonProps =
  | GenericButtonProps<{}>
  | ((params: {
      product: ProductSettingsT
      language: string
      translate: Translate
    }) => GenericButtonProps<{}> | undefined)

type CopyableItem = {
  icon: ReactNode
  value: string
  copyText: string
}

export type PaymentTermsModuleProps = {
  type: Module.PaymentTerms
  submitProps: Omit<ButtonProps, 'onClick'> & {
    onClick: (params: {
      financingPatch: ReturnType<typeof usePatchFinObj>['mutateAsync']
      financing: FinancingObject
      product: ProductSettingsT
    }) => void
    isDisabled: (params: {
      financing: FinancingObject
      configuration: PartnerFrontendConfiguration
      product: ProductSettingsT
    }) => boolean
  }
}

export type GenericError = {
  title: string
  financingId?: string
  financingObj?: FinancingObject
  failedFields?: string
  status?: string
}

export type GenericModuleProps = {
  type: Module.Generic
  bottomAnimationText?: string
  bottomButtonProps?: GenericButtonProps<{}>
  buttonProps?: PrimaryButtonProps
  contactSection?: Omit<ContactSectionProps, 'partner'>
  copyableItems?: (finObj: FinancingObject) => Array<CopyableItem>
  description?: string | Array<string>
  error?: GenericError
  hasConfetti?: boolean
  hasLoadingAnimation?: boolean
  imageSrc?: string
  poll?: Poll
  secondaryButtonProps?: GenericButtonProps<{}>
  style?: Partial<ModuleTheme['generic']>
  subtitle?: string
  title?: string
  titleMobile?: string
}

export type Steps = Array<{
  completedAt?: string | null
  customIcon?: ReactNode
  isActive?: boolean
  isCompleted?: boolean
  isWarning?: boolean
  localeSubtext?: string
  localeText: string
  localeWarningText?: string
  number?: number
  startedAt?: string | null
}>

export enum WaitingButtonType {
  None = 'None',
  ResendSMS = 'ResendSMS',
  Reset = 'Reset',
}

type WaitingButtonProps = Omit<ButtonProps, 'onClick'> & {
  label: string
  onClick: (params: {
    additionalData?: { hasChanged: boolean }
    financing: FinancingObject
    financingPatch: ReturnType<typeof usePatchFinObj>['mutateAsync']
    trackEvent: ReturnType<typeof useTrackEvent>
  }) => void
}

export type WaitingModuleProps = {
  type: Module.Waiting
  buttonType?: WaitingButtonType | ((financing: FinancingObject) => WaitingButtonType)
  description?: string | Array<string>
  descriptionMobile?: string
  hasAnimation?: boolean
  hasPlatformData?: boolean
  icon?: string
  isPollActive?: (financing: FinancingObject) => boolean
  mobileIcon?: string
  onMount?: (params: {
    appState: AppState
    dispatch: Dispatch
    finObj: FinancingObject
    patchFinObj: ReturnType<typeof usePatchFinObj>['mutateAsync']
  }) => void
  rejectionText?: string
  resendSMSProps?: {
    buttonProps: WaitingButtonProps
    label: string
  }
  resetProps?: {
    buttonProps: WaitingButtonProps
  }
  steps?: (params: {
    financing: FinancingObject
    product: ProductSettingsT
    stateLevels: StateLevels
  }) => Steps
  style?: Partial<ModuleTheme['waiting']>
  testLink?: (appConfig: AppConfig) => {
    url: string
    text: string
  }
  title?: string
}

// Used in BPL flow
export type LoanPreviewFormState = {
  loanAmount: number
  loanDuration: number
  insuranceAmount: number | null
  interestRate: number // interest rate in %
  interestRateAmount: number
}

// Used in credit flow
export type PreviewFormState = {
  monthlyRate: number
  interestRate: number
  financingPartner: string
  duration: number
  amount: number
}

export type InjectedModuleProps = {
  moduleSystem: string
}

export type CustomModuleProps = {
  type: Module.Custom
  render: (props: InjectedModuleProps & { data?: unknown }) => ReactNode
}

export type PartnerOnboardingModuleProps = PartnerInfoFormProps & {
  type: Module.PartnerOnboardingForm
  isReviewModuleSystem?: boolean
}

export type PartnerOnboardingConfirmationProps = {
  type: Module.PartnerOnboardingConfirmation
  getDocumentListToSendToBob?: (contactPersons: Array<PartnerContactPersonData>) => Array<string>
}

export type ModuleConfig = (
  | CalculatorModuleProps
  | CreditIdentityDataRetrievalSelectionProps
  | CreditSummaryProps
  | CustomerIDVerificationModuleProps
  | CustomerIntroductionProps
  | CustomerPlanSummaryModuleProps
  | CustomModuleProps
  | FormModuleProps
  | GenericModuleProps
  | InformationModuleProps
  | PartnerOnboardingModuleProps
  | PartnerOnboardingConfirmationProps
  | PaymentTermsModuleProps
  | ProcessingModuleProps
  | WaitingModuleProps
) &
  CommonModuleProps

export enum InternalOrderState {
  Crash = 'Crash',
}

export enum NavigationPlacement {
  AboveTitle = 'AboveTitle',
  BelowTitle = 'BelowTitle',
}

export enum NavigationActiveType {
  Single = 'Single',
  Fill = 'Fill',
}
export enum NavigationLoading {
  Animation1 = 'Animation1',
  Animation2 = 'Animation2',
}

export type BrandTheme = {
  appTheme: AppThemeConfig
  baseStyle: string
  brandName: string
  componentTheme: ComponentThemeConfig
  formTheme: FormThemeConfig
  moduleTheme: ModuleThemeConfig
}

export enum DevLanguage {
  DEV = 'DEV',
}

export enum UserLanguage {
  DEV = DevLanguage.DEV,
  DE = DeprecatedLanguage.DE,
  EN = DeprecatedLanguage.EN,
  FR = DeprecatedLanguage.FR,
  IT = DeprecatedLanguage.IT,
}

export type PartnerFrontendConfiguration = {
  styleConfig: StyleConfigDTO
  headerConfig: Partial<{
    address: {
      // Used on credit confirmation page
      companyNameLine1: string
      companyNameLine2: string
      street: string
      houseNr: string
      zipCode: string
      city: string
    }
    FAQs: [] // FAQ Links
    favicon: string // Fav icon
    faviconSVG: string // Fav icon SVG
    logo: string // REMOVE and take from partner variant (styleSettings.logo)
    showLogoLink: boolean
    showBobPageTitleLink: boolean // Controls whether to show the bob cooperation page title link (from locize)
    showFinalStepAnimation?: boolean // Control whether to show a little success animation on confirmation page
    showLanguageMenu: boolean // Show language selection menu
    showPageTitle: boolean // Controls weather to show the page title (from locize)
    showSidebar?: boolean
    showSupportNumber: boolean // Show support number in header (from partner)
  }>
  navigationConfig: Partial<{
    activeType?: NavigationActiveType
    loadingAnimation?: NavigationLoading
    placement?: NavigationPlacement
    showNavigationBelowTitle?: boolean
    showStepNumberAsCircle?: boolean
  }>
  footerConfig: Partial<{
    hideFooter?: boolean
    showCopyright?: boolean
    showLanguageMenu: boolean
    showSupportNumber?: boolean
  }>
  trackingConfig: Partial<{
    bobGTMTrackerId: string
    cookieBotDomainGroupId: string
  }>
  otherConfig: Partial<{
    defaultLanguage: UserLanguage
    disableLanguages: Array<UserLanguage>
    languageNamespacePartner: string
    showHasPartnerBankAccountField: boolean
    showMultiInputFieldLabels?: boolean
  }>
} & BrandTheme

export type SystemModules = {
  [ModuleState.Error]?: ModuleConfig
  [ModuleState.Loading]?: ModuleConfig
  [ModuleState.Ready]?: ModuleConfig
  [ModuleState.Rejection]?: ModuleConfig
  [ModuleState.Warning]?: ModuleConfig
}

export type IsModuleSystem =
  | {
      withStatesCheck: boolean // uses level states of isModuleSystem for check
    }
  | {
      withProduct: (params: { product: ProductSettingsT }) => boolean
    }
export type ModuleSystemConfig<K, G> = {
  id: K
  group: G
  modules: SystemModules
  computeModuleState: (params: { finObj?: FinancingObject; moduleSystem: string }) => ModuleState
  isModuleNavigationError?: (financing: FinancingObject) => boolean
  isModuleNotPartOfNavigation?: boolean
  isModuleSkipped?: IsModuleSystem
  isModuleSystem: Partial<{
    // orderStates: Array<BobProductsApp_BusinessServices_States_External_StateEnum>
    states: Array<StateLevel>
    withFinObj: (finObj: FinancingObject) => boolean
    withoutFinObj: () => boolean
  }>
}

export enum Engine {
  BplCustomerVerification = 'BplCustomerVerification',
  BplRetail = 'BplRetail',
  BplEcommerce = 'BplEcommerce',
  CreditCustomer = 'CreditCustomer',
  CreditInternal = 'CreditInternal',
  PartnerOnboarding = 'PartnerOnboarding',
  // Ecommerce = 'Ecommerce',
  // POS = 'POS',
  // RetailCustomer = 'RetailCustomer',
  // RetailPartner = 'RetailPartner',
}

export type EngineConfig<K = string, G = string> = {
  type: Engine
  errorSystem: ModuleSystemConfig<K, G>
  computeInitialModuleSystem?: (finObj: FinancingObject) => K | undefined
  moduleSystems: Array<ModuleSystemConfig<K, G>>
  groupToLabel: { [k: string]: string }
}

export enum RedirectToMerchantEnum {
  Success = 'Success',
  Rejection = 'Rejection',
}
