import { Div, Img, P } from 'frr-web/lib/html'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppTheme, useAppTheme, useCSSStyles } from '../../../shared/theme/app.theme'
import { useProduct } from '../../context/PartnerProduct.context'
import { FooterLink } from './FooterLink'
import { Link } from 'frr-web/lib/components/Link'

const FooterLanguageSelector = React.lazy(() => import('./FooterLanguageSelector'))

export type Props = {
  style?: Partial<AppTheme['footer']>
}

export const Footer = (props: Props) => {
  const { t: translate } = useTranslation()
  const theme = useAppTheme()
  const getCSSStyles = useCSSStyles(theme, 'footer')(props.style)
  const { product, configuration } = useProduct()

  if (configuration.footerConfig.hideFooter) {
    return null
  }

  return (
    <Div {...getCSSStyles('outerWrapper')}>
      <Div {...getCSSStyles('innerWrapper')}>
        {configuration.footerConfig.showCopyright && (
          <Div {...getCSSStyles('copyrightWrapper')}>
            <P label={'footer.copyrightText'} {...getCSSStyles('copyrightSubstring')} />
          </Div>
        )}

        <Div {...getCSSStyles('footerContentWrapper')}>
          <Div {...getCSSStyles('legalWrapper')}>
            <FooterLink
              linkText={'footer.legalLinkText'}
              linkUrl={translate('footer.legalLinkUrl')}
              target='_blank'
            />
          </Div>

          {configuration.footerConfig.showLanguageMenu && <FooterLanguageSelector style={props.style} />}

          {product.partnerCustomerSupportPhoneNumber !== null &&
            product.partnerCustomerSupportPhoneNumber > '' && (
              <Div {...getCSSStyles('contactWrapper')}>
                <Link
                  className='link__footer'
                  icon={{ type: 'contact', style: getCSSStyles('contactIcon') }}
                  label={product.partnerCustomerSupportPhoneNumber}
                  style={getCSSStyles('contactLabel')}
                  onClick={() =>
                    window.open(
                      `tel:${product.partnerCustomerSupportPhoneNumber!.replace(' ', '')}`,
                      '_self',
                    )
                  }
                />

                {/* <FooterLink
                  linkUrl={`tel:${product.partnerCustomerSupportPhoneNumber.replace(' ', '')}`}
                  target='_self'
                >
                  <Img
                    alt='contact'
                    src='https://stbobfspfrontendassets.blob.core.windows.net/bobzeroassets/3073.png'
                    {...getCSSStyles('contactIcon')}
                  />
                  <P
                    {...getCSSStyles({ contactLabel: true })}
                    label='footer.contactText'
                    data={{
                      supportNumber: product.partnerCustomerSupportPhoneNumber,
                    }}
                  />
                </FooterLink> */}
              </Div>
            )}
        </Div>
      </Div>
    </Div>
  )
}
